<template>
  <about-me></about-me>
</template>

<style scoped></style>

<script>
import AboutMe from '../components/AboutMe.vue'
export default {
  components: { AboutMe }
}
</script>
>
